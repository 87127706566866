<template>
  <div>
    <Container class="mt-12 mb-4 lg:mb-24 lg:mt-16">
      <h2 v-if="title && type !== 'filter'"
          class="max-w-3xl text-3xl lg:text-5xl leading-[1.2] text-white tracking-[-1px] mb-6"
      >
        {{ title }}
      </h2>

      <SectionJobListFilter v-if="type === 'filter'"
                            ref="filter"
                            :title="title"
                            :area="selectedArea"
                            :location="selectedLocation"
                            :employment-type="selectedEmploymentType"
                            :work-types="selectedWorkTypes"
      />

      <div v-if="type === 'filter'"
           class="mt-6 lg:mt-14"
      >
        <SectionJobListTable v-for="(area, i) in areasWithJobs"
                             :key="i"
                             :title="area.title"
                             :jobs="area.jobs"
        />
      </div>

      <div v-else-if="type === 'location' || type === 'early'">
        <SectionJobListTable v-if="jobs"
                             :jobs="(jobs.slice(0, 4) as unknown as JobType[])"
                             class="pt-0 pb-0 lg:pb-0"
        />
      </div>

      <div v-if="filteredJobs.length === 0 && type === 'filter'"
           class="max-w-md px-6 mx-auto mt-12 mb-24 text-center text-white md:mt-20 md:mb-40"
      >
        <h3 class="mt-8 text-3xl text-center">
          No results to show.
        </h3>

        <p class="mt-4 text-lg font-normal text-center text-gray-100">
          No results match the filter criteria. Remove filter or clear all filters to show results.
        </p>

        <button class="relative block mx-auto mt-8 pb-0.5 bottom-line-inverted"
                @click="filter.clearFilter()"
        >
          Clear filters
        </button>
      </div>

      <div v-if="filteredJobs.length === 0 && type !== 'filter'"
           class="text-gray-100"
      >
        <p class="mb-16 -mt-4 text-lg md:mt-8">
          At the moment there are no open positions for the given selection.
          <NuxtLink :href="isInternalJobReferrals ? '/internal-job-referrals' : '/careers/jobs'"
                    class="underline"
          >
            Explore all open positions
          </NuxtLink>
        </p>
      </div>
    </Container>

    <SectionHeading v-if="type === 'location' && filteredJobs.length > 4 && !isInternalJobReferrals"
                    class="!-mt-4 lg:!-mt-24"
                    :title="`There are ${filteredJobs.length - 4} additional positions in ${locationLabel}`"
                    type="secondary"
                    :cta="{ title: `Explore positions`, href: `/careers/jobs?location=${location}`, type: 'filled' }"
    />
  </div>
</template>

<script setup lang="ts">
import type { JobType } from '~/models/jobs'
import { SPONTANEOUS_APPLICATION_ID } from '~/constants/common.mjs'
import { EMPLOYMENT_TYPES, EmploymentTypeLabel, AREAS, LOCATIONS, WORK_TYPES } from '~/constants/jobs'

interface JobListingProps {
  title?: string
  type: 'filter' | 'location' | 'early'
  location?: string
}

const props = defineProps<JobListingProps>()

const filter = ref()
const route = useRoute()
const { data } = await useAsyncData('jobs', () => queryContent<JobType>('jobs').find())
const { isInternalJobReferrals } = useInternalJobreferrals()

const selectedArea = computed(() => {
  return AREAS.find(area => area.key === route.query.area)
})

const selectedLocation = computed(() => {
  return LOCATIONS.find(location => location.key === route.query.location)
})

const selectedEmploymentType = computed(() => {
  return EMPLOYMENT_TYPES.find(employmentType => employmentType.key === route.query.employmentType)
})

const selectedWorkTypes = computed(() => {
  if (!route.query.workType) {
    return []
  }

  const queryWorkTypeKeys = Array.isArray(route.query.workType) ? route.query.workType : route.query.workType.split(',')

  return WORK_TYPES.filter(workType => {
    return queryWorkTypeKeys.some(queryWorkTypeKey => queryWorkTypeKey === workType.key)
  })
})

// location name with first letter uppercase
const locationLabel = computed(() => {
  if (props.location) {
    return props.location.charAt(0).toUpperCase() + props.location.slice(1)
  }

  return ''
})

const jobs = computed(() => {
  if (!data || !data.value) {
    return
  }

  return data.value.filter(job => {
    if (job.id === SPONTANEOUS_APPLICATION_ID) {
      return false
    }

    if (props.type === 'location') {
      return job.locations.some((location: { key: string }) => location.key === props.location.toLowerCase())
    }

    if (props.type === 'early') {
      return job.employmentType === EmploymentTypeLabel.INTERNSHIP
        || job.employmentType === EmploymentTypeLabel.STUDENT
        || job.title.toLowerCase().includes('junior')
    }

    return true
  })
})

const filteredJobs = computed(() => {
  if (!jobs.value) {
    return []
  }

  return jobs.value.filter(job => {
    const areaMatch = !selectedArea.value || job.area === selectedArea.value.label
    const locationMatch = !selectedLocation.value || job.locations.some((location: { key: string }) => {
      return location.key === selectedLocation.value.key
    })
    const employmentTypeMatch = !selectedEmploymentType.value || job.employmentType === selectedEmploymentType.value.label
    const workTypeMatch = !selectedWorkTypes.value.length || selectedWorkTypes.value.some(selectedWorkType => {
      return job.workTypes.includes(selectedWorkType.label)
    })

    return areaMatch && locationMatch && employmentTypeMatch && workTypeMatch
  })
})

const areasWithJobs = computed(() => {
  const areas = filteredJobs.value.map(job => job.area)

  return [...new Set(areas)].map((area: string) => {
    return ({
      title: area,
      jobs: filteredJobs.value.filter(job => job.area === area)
    })
  })
})
</script>
